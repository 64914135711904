import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Paper, List, ListItem, ListItemText, Button } from '@mui/material';
import axios from 'axios';

const FinancialNews = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [newsFeed, setNewsFeed] = useState([]);

  const fetchSuggestions = async (query) => {
    if (query.length > 1) {
      try {
        const response = await axios.get(`/api/search_companies?query=${query}`);
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setSelectedCompany(null);
    fetchSuggestions(value);
  };

  const handleSuggestionClick = (company) => {
    setQuery(company.name);
    setSelectedCompany(company);
    setSuggestions([]);
    fetchNewsFeed(company.name, 'company');
  };

  const fetchNewsFeed = async (query, category) => {
    try {
      const response = await axios.get(`/api/news_feed?query=${query}&category=${category}`);
      setNewsFeed(response.data);
    } catch (error) {
      console.error('Error fetching news feed:', error);
    }
  };

  const handleSearch = () => {
    const category = selectedCompany ? 'company' : 'free-text';
    fetchNewsFeed(query, category);
  };

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Financial News
        </Typography>
        <TextField
          fullWidth
          label="Search companies"
          variant="outlined"
          value={query}
          onChange={handleInputChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          sx={{ mt: 3, mb: 2 }}
        />
        {suggestions.length > 0 && (
          <Paper>
            <List>
              {suggestions.map((company) => (
                <ListItem button key={company.id} onClick={() => handleSuggestionClick(company)}>
                  <ListItemText primary={`${company.name} (${company.ticker})`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        <Box sx={{ width: '100%', mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">News Feed</Typography>
          <Button variant="contained" color="primary">
            Customize Feed
          </Button>
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          {newsFeed.length > 0 ? (
            newsFeed.map((news, index) => (
              <Paper key={index} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6">{news.title}</Typography>
                <Typography variant="body2">{news.description}</Typography>
              </Paper>
            ))
          ) : (
            <Typography variant="body1">No news available</Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default FinancialNews;