import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: theme.spacing(12, 0),
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const StyledImage = styled('img')({
  width: '100%',
  height: '400px',
  objectFit: 'cover',
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
});

const StyledListItem = styled(ListItem)({
  padding: '8px 0',
});

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '30px',
});

const StyledListItemText = styled(ListItemText)({
  margin: 0,
});

const FeatureBlock = ({ title, description, imageUrl }) => {
  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={6}>
        <StyledImage src={imageUrl} alt={title} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 3, color: 'primary.main' }}>
          {title}
        </Typography>
        <List>
          {description.map((item, index) => (
            <StyledListItem key={index}>
              <StyledListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </StyledListItemIcon>
              <StyledListItemText 
                primary={item} 
                primaryTypographyProps={{ 
                  variant: 'body1',
                  style: { 
                    whiteSpace: 'normal', 
                    wordWrap: 'break-word',
                    fontSize: '1.1rem',
                    lineHeight: 1.6,
                  }
                }}
              />
            </StyledListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

const Features = () => {
  const features = [
    {
      title: "Search anything you want, we got you covered",
      description: [
        "Discover CDMO companies discussing China Plus One strategy.",
        "Find companies with 20%+ revenue guidance and PE ratio under 30.",
        "Identify companies in semiconductor testing and packaging.",
      ],
      imageUrl: "/search.jpg",
    },
    {
      title: "Chat with our Financial AI Agent",
      description: [
        "Get expert guidance on financial planning and investment strategies.",
        "Learn the best ways to invest in the current market.",
        "Understand key economic indicators and their impact.",
        "Explore top-performing sectors in the market.",
      ],
      imageUrl: "/chatv2.jpg",
    },
    {
      title: "Deep Dive into Companies",
      description: [
        "Access structured information from concall transcripts, rating reports, and analyst notes.",
        "Evaluate management guidance consistency with past performance.",
        "Identify key risks associated with companies.",
        "Understand the primary drivers of company revenue.",
      ],
      imageUrl: "/deepv2.jpg",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return (
    <StyledBox>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          component="h1"
          align="center"
          gutterBottom
          sx={{ mb: 6, fontWeight: 'bold' }}  // Removed the color property
        >
          AI-Powered Financial Analytics
        </Typography>
        <Slider {...settings}>
          {features.map((feature, index) => (
            <FeatureBlock key={index} {...feature} />
          ))}
        </Slider>
      </Container>
    </StyledBox>
  );
};

export default Features;