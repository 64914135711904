import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CssBaseline from "@mui/material/CssBaseline";
// Import components
import Navbar from "./components/Nav/Navbar";
import Home from "./components/Home";
import Login from "./components/Login";
import Waitlist from "./components/Waitlist";
import Register from "./components/Register";
import SearchPage from "./components/SearchPage";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import FinancialNews from './components/FinancialNews';

// Create a theme instance
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
  },
});

function App() {
  const location = useLocation();
  const isPrivateRoute = ['/search', '/financial-news'].includes(location.pathname);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {!isPrivateRoute && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/search"
            element={
              <PrivateRoute>
                <SearchPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/financial-news"
            element={
              <PrivateRoute>
                <FinancialNews />
              </PrivateRoute>
            }
          />
        </Routes>
        {!isPrivateRoute && <Footer />}
      </div>
    </ThemeProvider>
  );
}

export default App;