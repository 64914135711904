import React from 'react';
import PrivateNavbar from './Nav/PrivateNavbar';

const PrivateLayout = ({ children }) => {
  return (
    <div>
      <PrivateNavbar />
      <div>{children}</div>
    </div>
  );
};

export default PrivateLayout;